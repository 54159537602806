<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="UIdevelopmentproject" style="width:100vw">
  <div id="ad67f482">
    开发中列表
  </div>
  <div id="ddd46c1c">
    <dal-comp-development id="cmpddd46c1c">
    </dal-comp-development>
  </div>
  <el-pagination id="cc929b1b">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_comp_development from '../components/dal-comp-development'
export default{
  components:{
    'dal-comp-development':dal_comp_development
  },
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ad67f482{
  padding-top:100px;
}
#ad67f482{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ddd46c1c{
  width:1323px;
  margin-top:30px;
  padding-left:163px;
}
#cc929b1b{
  width:302px;
  margin-top:60px;
  padding-left:673px;
  padding-bottom:1492px;
}
/*在此注释下方添加自定义样式*/
</style>
